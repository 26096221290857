import { datadogRum } from "@datadog/browser-rum";


window.addEventListener("CookiebotOnLoad", () => {
  if (Cookiebot.consent.statistics) {
    datadogRum.init({
      clientToken: 'pub0a4c6b5402e6a56e04f921f43bff953a',
      applicationId: '875a8f74-15ef-4980-bdae-89425fd9fed5',
      service: 'sso-server',
      env: 'production',
      trackUserInteractions: true,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: 'mask-user-input',
    })
  }
})

